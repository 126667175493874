import axios from 'axios';
import Cookies from 'js-cookie';
import { axiosProtected, axiosPublic, baseUrl } from '../config';
import {
  FETCH_THEME, FETCH_CONTENT, FETCH_ACCOUNTDATA,
  FETCH_SECURITY, USER_LOGOUT, FETCH_CONTENT_LANDING, SET_EDIT_PROFILE,SET_EDIT_PROFILE_DATA,
  FETCH_ESCROW_CONTENT, RELEASE_ANNOUNCEMENT, EMERGENCY_ALERT, FETCH_LOAN_DATA, PROPERTY_REPAIR
} from '../types';
import { getPublicIp, getUserType, getAppSource } from '../helpers'
import { decrypt, encrypt } from '../helpers/encrypt';

export const preLoginToken = async () => {
  const data = {
    "userName": "jPcimC6LUQCyKHjg7ghNd5L6pKFAbX0uXKcJfTHVG6HayE/8Dl7MZ+czpCfQOyYQ",
    "password": "EZ6XwN+qSDMIDFcVPBuE59XIXyLRYmdZ5o72Xt6MeGvX3HzMHr98dxjE269+S2tj",
    "privateLabelCode": Cookies.get("privateLabelUrl"),
    "ipAddress": await getPublicIp(),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType()
  }
  try {
    const token = await axios.post(`${baseUrl}/Authentication/GetOTToken`, data);
    Cookies.set("publicToken", token.data.accessToken, { expires: 1 })
  } catch (e) {
    console.log("token", e )
  }
}

export const handleSignIn = async (data) => {

  try {
    const token = await axios.post(`${baseUrl}/Authentication/AuthenticateUser`, data);
    Cookies.set("privateToken", token.data.accessToken);
    Cookies.set("accessTokenID", token.data.accessTokenID);
    return token.data
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return null
  }
}

export const deleteAccessTokenID = async () => {

  var releaseIds = [];
  if (Cookies.get('releaseIds') !== undefined) {
    const ids = JSON.parse(Cookies.get('releaseIds'))
    if(ids.length > 0) {
      releaseIds = ids.map((item) => item.releaseId)
    } else {
      releaseIds = []
    }
  } else {
    releaseIds = []
  } 

  const data = {
    "accessTokenID": Cookies.get("accessTokenID"),
    "privateLabelCode": Cookies.get("privateLabelUrl"),
    // "releaseIds": releaseIds,
    "ipAddress": await getPublicIp(),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName":  Cookies.get("userName")
  }
  try {
    await axios.post(`${baseUrl}/Authentication/logout`, data);
  } catch (error) {
    return null
  }
}

export const deleteAccessTokenIDAdmin = async () => {
  const data = {
    "accessTokenID": Cookies.get("accessTokenIdAdmin"),
    // "privateLabelCode": Cookies.get("privateLabelUrl"),
    "ipAddress": await getPublicIp(),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    await axios.post(`${baseUrl}/Authentication/logout`, data);
  } catch (error) {
    return null
  }
}


export const checkForMultiAccount = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": await getPublicIp(),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const accounts = await axiosProtected.post(`${baseUrl}/User/GetMultiUserName`, data);
    return accounts.data
  } catch (error) {
    return null
  }
}


export const deleteMigratedUserName = async (data) => {
  const { lstMultiUsers, selectedUserName } = data;
  const newData = {
    lstMultiUsers,
    selectedUserName,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": await getPublicIp(),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const accounts = await axiosProtected.post(`${baseUrl}/User/DeleteMigratedUserName`, newData);
    return accounts.data
  } catch (error) {
    return null
  }
}


export const recoverUsername = async (data) => {
  try {
    const username = await axiosPublic.post('/OT/RecoverUserName', data);
    return username.data
  } catch (e) {
    return null
  }
}

export const recoverPassword = async (data) => {
  try {
    const otp = await axiosPublic.post('/OT/RecoverPassword', data);
    return otp.data
  } catch (e) {
    return null
  }
}

export const validateOTP = async (data) => {
  try {
    const otp = await axiosPublic.post('/OT/ValidateOTP', data);
    if (otp && otp.data && (otp.data.lstResponseStatus[0].statusCode === '0' || otp.data.lstResponseStatus[0].statusCode === '1001')) {
      return otp.data
    }
    return null
  } catch (e) {
    return null
  }
}

export const resendCode = async (data) => {
  try {
    const otp = await axiosPublic.post('/OT/SendOTP', data);
    return otp.data
  } catch (e) {
    return null
  }
}

export const resendEmail = async (data) => {
  try {
    const otp = await axiosPublic.post('/OT/ResendRecoverPasswordEmail', data);
    return otp.data
  } catch (e) {
    return null
  }
}

export const validateUrl = async (data) => {
  try {
    const res = await axiosPublic.post("/OT/ValidatePassURL", data);
    if (res && res.data && res.data.lstResponseStatus[0].statusCode === '0') {
      return res.data
    }
    return null
  } catch (e) {
    return null
  }
}

export const resetPassword = async (data) => {
  try {
    const res = await axiosPublic.post("/OT/ReSetPassword", data);
    return res.data
  } catch (e) {
    return null
  }
}

export const getTheme = () => async dispatch => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    let theme;
    const privatelabel = window.location.pathname.split('/')[1];
    if (Cookies.get('privateToken') && privatelabel.toLowerCase() == 'bsi') {
      theme = await axiosProtected.post('/Dashboard/GetPrivateLabelTheme', data)
    } else {
      theme = await axiosPublic.post('/OT/GetPrivateLabelTheme', data);
    }
    dispatch({
      type: FETCH_THEME,
      payload: theme.data
    })
  } catch (e) {
    //error
  }
}

export const getContent = (pageId) => async dispatch => {
  const data = {
    "pageCode": pageId,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const content = await axiosPublic.post('/OT/GetPageContent ', data);
    if (content && content.data && content.data.lstResponseStatus[0].statusCode === '0') {
      dispatch({
        type: FETCH_CONTENT,
        payload: content.data
      })
      return content.data.lstPageContentDetails
    }
  } catch (e) {
    return null
  }
}

export const getPageContent = (pageId) => async dispatch => {
  const data = {
    "pageCode": pageId,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const content = await axiosPublic.post('/OT/GetPageContent ', data);
    if (content && content.data && content.data.lstResponseStatus[0].statusCode === '0') {
      dispatch({
        type: FETCH_CONTENT_LANDING,
        payload: content.data
      })
    }
  } catch (e) {
    return null
  }
}

export const getMultiLoanDetails = async (ssn, foreignId, accountNumber, zipCode) => {
  const data = {
    "borrowerfullSSN": ssn,
    "ForeignId": foreignId,
    "accountNumber": accountNumber,
    "ZipCode": zipCode,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const details = await axiosPublic.post('/OT/GetMultiLoanDetails', data);
    return details.data
  } catch (e) {
    return null
  }
}

export const getAccountData = (data) => async dispatch => {
  dispatch({
    payload: data,
    type: FETCH_ACCOUNTDATA
  })
}


export const resendActivationEmail = async (data) => {
  try {
    const otp = await axiosPublic.post('/OT/ResendActivationEmail ', data);
    return otp.data
  } catch (e) {
    return null
  }
}


export const getPrivacySecurityContent = (pageCode) => async (dispatch) => {
  const data = {
    "pageCode": pageCode,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const content = await axiosPublic.post('/OT/GetPageContent ', data);
    if (content && content.data && content.data.lstResponseStatus[0].statusCode === '0') {
      dispatch({
        type: FETCH_SECURITY,
        payload: content.data
      });
      return content.data
    }
  } catch (e) {
    return null
  }
}

export const setActiveAccountNo = (accountNumber) => {
  Cookies.set("activeAccountNo", accountNumber)
}

export const getActiveAccountNo = () => {
  let acccountNumber = Cookies.get("activeAccountNo")
  return acccountNumber ? acccountNumber : ''
}

export const getBankAccountDetail = async (data) => {
  const newData = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const response = await axiosProtected.post('/Payment/GetBankAccountDetail', data ? data : newData);
    if (response && response.data && response.data.lstResponseStatus && response.data.lstResponseStatus[0].statusCode === '0') {
      return response.data;
    }
    return null;
  } catch (e) {
    return null
  }
}

export const getLandingPageTiles = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const tiles = await axiosPublic.post('/OT/GetLendingPageTiles', data);
    if (tiles && tiles.data && tiles.data.lstResponseStatus[0].statusCode === '0') {
      return tiles.data.lendingPageTiles
    }
  } catch (e) {
    return []
  }
}

// get all state
export const getStateList = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const response = await axiosProtected.post('/User/GetStateList', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getAllStateList = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const response = await axiosPublic.post('/OT/GetStateList', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getBorrowerCoborrowerName = async (data) => {
  try {
    const response = await axiosProtected.post('Customer/GetBorrowerCoborrowerName', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getCalculateProcessingFee = async (data) => {
  try {
    const response = await axiosProtected.post('/Payment/GetCalculateProcessingFee', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getOneTimeACHPaymentDetails = async (data) => {
  try {
    const response = await axiosProtected.post('/Payment/GetOneTimeACHPaymentDetails', data);
    return response.data;
  } catch (e) {
    return null
  }
}

// add third party requester
export const thirdPartyAuthorization = async (data) => {
  try {
    const response = await axiosPublic.post('/OT/ThirdPartyAuthorization', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const thirdPartyAuthorizationPrivate = async (data) => {
  try {
    const response = await axiosProtected.post('/ThirdParty/AddNewThirdParty', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getThirdPartyData = async (data) => {
  try {
    const response = await axiosProtected.post('/ThirdParty/GetThirdPartyData', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const getContact = async (data) => {
  try {
    const response = await axiosProtected.post('/MyProfile/GetMyProfile', data);
    if (response.status === '200' || response.status === 200) {
      return response.data;
    }
    return null
  } catch (e) {
    return null
  }
}

export const userLogoutState = () => async (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
    payload: null
  })
}

export const insertActivityLog = async (data) => {
  try {
    const res = await axiosProtected.post('/Dashboard/InsertActivityLog', data);
    return res;
  } catch (e) {
    return null
  }
}

export const insertTelemetryActivity = async (data) => {
  try {
    const res = await axiosProtected.post('/FAQ/InsertFAQActivity', data);
    return res;
  } catch (e) {
    return null
  }
}

//Admin
export const handleAdmin = async (data) => {

  try {
    const token = await axios.post(`${baseUrl}/Authentication/AuthenticateAdminUser`, data);
    Cookies.set("adminToken", token.data.accessToken);
    Cookies.set("roleID", token.data.roleID);
    Cookies.set("accessTokenIdAdmin", token.data.accessTokenID);
    return token.data
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
    return null
  }
}

export const setEditProfileComponent = (payload) => async (dispatch) => {
  dispatch({
    type: SET_EDIT_PROFILE,
    payload: payload
  });
}
export const setEditProfileComponentData = (payload) => async (dispatch) => {
  dispatch({
    type: SET_EDIT_PROFILE_DATA,
    payload: payload
  });
}

//Get subordination status
export const getThirdPartySubOrdinateData = async (data) => {
  try {
    const response = await axiosPublic.post('/OT/GetSubordination', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Get invalid ssn/ein borrower data 
export const getInValidSSNBorrowerData = async (data) => {
  try {
    const response = await axiosProtected.post('/DashBoard/GetInValidSSNBorrowerData', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Update ssn/ein 
export const updateSSN = async (data) => {
  try {
    const response = await axiosPublic.post('/OT/UpdateSSNAPIScript', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Get client short name
export const getClientName = (data) => async dispatch => {
  try {
    const response = await axiosProtected.post('/Dashboard/GetLoanInfo', data);
    if (response && response?.data?.lstResponseStatus && response?.data?.lstResponseStatus[0]?.statusCode == 0) {
      dispatch({
        type: FETCH_LOAN_DATA,
        payload: response.data
      })
    }
    return response.data;
  } catch (e) {
    return null   
  }
}

//Save term and condition data 
export const saveUserTermandCondition = async (data) => {
  try {
    const response = await axiosProtected.post('/DashBoard/SaveUserTermandCondition', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Get escrow/insurance data 
export const getEscrowInsuranceData = (accountNumber) => async dispatch => {
  const data = {
    "accountNumber": accountNumber,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const response = await axiosProtected.post('/Escrow/InsuranceTax', data);
    if (response && response?.data?.responseData?.lstResponseStatus && response?.data?.responseData.lstResponseStatus[0]?.statusCode === '0') {
      dispatch({
        type: FETCH_ESCROW_CONTENT,
        payload: response.data?.responseData.responseData
      })
    }
    return response.data;
  } catch (e) {
    return null
  }
}

//Get escrow documents
export const getEscrowDocuments = async (accountNumber) => {
  const data = {
    "accountNumber": accountNumber,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const response = await axiosProtected.post('/Escrow/Documents', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Upload escrow documents
export const uploadEscrowDocuments = async (accountNumber, lineType,  file, fileName, count, replace, documentCode, documentId) => {
 
  try {
    const formData = new FormData();
    formData.append('accountNumber', accountNumber);
    formData.append('lineType',  lineType);
    formData.append('documentId',  replace ? documentId : 0);
    formData.append('documentName',  documentCode + '.' + file.name.split(".").pop());
    formData.append('documentFile', file);
    formData.append('documentCount', replace ? count : count + 1);
    formData.append('privateLabelUrl', Cookies.get("privateLabelUrl"));
    formData.append('browser', Cookies.get("browser"));
    formData.append('appSource', getAppSource());
    formData.append('userType', getUserType());
    formData.append('ipAddress', Cookies.get('publicIp'));
    const response = await axiosProtected.post('/Escrow/uploadDocument', formData);
    return response.data;
  } catch (e) {
    return null
  }
}

//Delete escrow documents
export const deleteEscrowDocuments = async (accountNumber, documentId) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'documentId': documentId,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/Escrow/DeleteDocument', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Delete escrow documents
export const downloadEscrowDocument = async (accountNumber, documentId) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'documentId': documentId,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/Escrow/DownloadDocument', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Reset and Cancel escrow documents
export const resetEscrowDocument = async (accountNumber) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'documentId': 0,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/Escrow/ResetDocument', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const submitEscrowDocuments = async (accountNumber, escrows) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'escrowLines': escrows,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/Escrow/AddRemoveEscrow', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Update esrow status
export const updateDisplayEscrowStatus = async (accountNumber, citNumber, controlNumber) => {
  const data = {
    "accountNumber": accountNumber,
    "citNumber": citNumber,
    "controlNumber": controlNumber,
    "displayMessageFlag": false,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const response = await axiosProtected.post('/Escrow/UpdateDisplayStatusFlag', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Release anouncement
export const releaseAnnouncement = () => async dispatch => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const response = await axiosProtected.post('/Release/GetAnnouncement', data);
    if (response && response?.data?.responseData?.lstResponseStatus && response?.data?.responseData.lstResponseStatus[0]?.statusCode === '0') {
      dispatch({
        type: RELEASE_ANNOUNCEMENT,
        payload: response.data?.responseData.responseData
      })
    }
    return response.data;
  } catch (e) {
    return null
  }
}

//hide release announcement
export const updateReleaseAnnouncements = async () => {
  var releaseIds = [];
  if (Cookies.get('releaseIds') !== undefined) {
    const ids = JSON.parse(Cookies.get('releaseIds'))
    if(ids.length > 0) {
      releaseIds = ids.map((item) => item.releaseId)
    } else {
      releaseIds = []
    }
  } else {
    releaseIds = []
  } 

  const data = {
    "releaseIds": releaseIds,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }
  try {
    const response = await axiosProtected.post('/Release/UpdateDisplay', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//GetEmergencyAlert
export const getEmergencyAlert = () => async dispatch => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const response = await axiosPublic.post('/OT/GetEmergencyAlert', data);
    if (response && response?.data?.responseData?.lstResponseStatus && response?.data?.responseData.lstResponseStatus[0]?.statusCode === '0') {
      dispatch({
        type: EMERGENCY_ALERT,
        payload: response.data?.responseData.responseData
      })
    }
    return response.data;
  } catch (e) {
    return null
  }
}


//Upload IdentityTheft documents
export const uploadIdentityTheftDocuments = async (accountNumber,  file, replace, documentName, documentId, docTypeId) => {
 
  try {
    const formData = new FormData();
    formData.append('accountNumber', accountNumber);
    formData.append('documentId',  replace ? documentId : 0);
    formData.append('documentName',  documentName + '.' + file.name.split(".").pop())
    formData.append('documentFile', file);
    formData.append('documentCount', 1);
    formData.append('documentTypeId', docTypeId);
    formData.append('privateLabelUrl', Cookies.get("privateLabelUrl"));
    formData.append('browser', Cookies.get("browser"));
    formData.append('appSource', getAppSource());
    formData.append('userType', getUserType());
    formData.append('ipAddress', Cookies.get('publicIp'));
    const response = await axiosProtected.post('/IdentityTheft/uploadDocument', formData);
    return response.data;
  } catch (e) {
    return null
  }
}

//Delete identity theft documents
export const deleteIdentityTheftDocuments = async (accountNumber, documentId) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'documentId': documentId,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/IdentityTheft/DeleteDocument', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Download identity theft documents
export const downloadIdentityTheftDocument = async (accountNumber, documentId) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'documentId': documentId,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/IdentityTheft/DownloadDocument', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Get identity theft documents mapping
export const identityTheftDocumentMapping = async (accountNumber) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/IdentityTheft/Documents', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Submit identity theft documents
export const submitIdentityTheftDocuments = async (accountNumber, answer1, answer1Document, answer2, answer2Document, answer3) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    "answer1": answer1,
    "answer1Document": answer1Document,
    "answer2": answer2,
    "answer2Document": answer2Document,
    "answer3": answer3?.join(", "),
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/IdentityTheft/submit', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Cancel identity theft documents
export const cancelIdentityTheftDocument = async (accountNumber) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/IdentityTheft/ResetDocument', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const handleSsoLogin = async (data) => {
  try {
    const token = await axios.post(`${baseUrl}/Authentication/AdminSSOLogin`, data);
    Cookies.set("adminToken", token.data.accessToken);
    Cookies.set("roleID", token.data.roleID);
    Cookies.set("accessTokenIdAdmin", token.data.accessTokenID);
    return token.data
  } catch (error) {
    if (error.response.status === 401) {
      return error.response.data;
    }
  }
}

//Fetch faqs
export const fetchFaqs = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get("publicIp"),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const res = await axiosPublic.post('/OT/Faq', data);
    return res.data
  } catch (e) {
    return null
  }
}
//Validate email and ssn

export const validateEmailSsn = async (data) => {
  try {
    const username = await axiosPublic.post('/OT/BorrowerAuth', data);
    return username.data
  } catch (e) {
    return null
  }
}

//Validate otp

export const validateOtp = async (data) => {
  try {
    const otp = await axiosPublic.post('/OT/ValidateLoginOTP', data);
    // if (otp.data.responseData?.responseData.isOTPValid && (otp.data.responseData?.responseData?.token !== 'null') && (!otp.data.responseData?.responseData?.token?.accessToken?.includes('locked')) && (!otp.data.responseData?.responseData?.token?.accessToken?.includes('verification'))) {
    //   console.log("token", otp.data.responseData?.responseData?.token)

    //   Cookies.set("privateToken", otp.data.responseData?.responseData?.token?.accessToken);
    //   Cookies.set("accessTokenID", otp.data.responseData?.responseData?.token?.accessTokenID);
    // }
    return otp.data
  } catch (e) {
    return null
  }
}


//Account login

export const accountLoginWithEmail = async (data, token) => {
  try {
    const otp = await axios.post(`${baseUrl}/Auth/AccountLogin`,data, {
      headers: {
       'Authorization': `Bearer ${token}`,
       'Content-Type' : 'application/json'
      }});
    // const otp = await axiosProtected.post('/Auth/AccountLogin', data);
    if ((otp.data.responseData?.responseData.token !== 'null') && (!otp.data.responseData?.responseData.token.accessToken?.includes('locked')) && (!otp.data.responseData?.responseData?.token?.accessToken?.includes('verification'))) {
     Cookies.set("privateToken", otp.data.responseData?.responseData.token.accessToken);
     Cookies.set("accessTokenID", otp.data.responseData?.responseData.token.accessTokenID);
    }
    return otp.data
  } catch (e) {
    return null
  }
}

//Insert Refinance Request
export const submitRefinanceRequest = async (data) => {
  try {
    const res = await axiosProtected.post('/Refinance/InsertContactMeRequest', data);
    return res.data
  } catch (e) {
    return null
  }
}

//FAQ telemetry changes
export const submitRating = async(data)=>{
  try {
    const res = await axiosPublic.post('/OT/InsertFAQActivity', data);
    return res.data
  } catch (e) {
    return null
  }
}

//Upload payoff documents
export const uploadPayoffDocuments = async (accountNumber,  file, count, replace, documentId, counter) => {
  try {
    const formData = new FormData();
    formData.append('accountNumber', accountNumber);
    formData.append('documentId',  replace ? documentId : 0);
    formData.append('documentName', 'BORRCORR_' + counter + '.' + file.name.split(".").pop());
    formData.append('documentFile', file);
    formData.append('documentCount', replace ? count : count + 1);
    formData.append('privateLabelUrl', Cookies.get("privateLabelUrl"));
    formData.append('browser', Cookies.get("browser"));
    formData.append('appSource', getAppSource());
    formData.append('userType', getUserType());
    formData.append('ipAddress', Cookies.get('publicIp'));
    const response = await axiosProtected.post('/Payoff/uploadDocument', formData);
    return response.data;
  } catch (e) {
    return null
  }
}

//Delete payoff documents
export const deletePayoffDocuments = async (accountNumber, documentId) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'documentId': documentId,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/Payoff/DeleteDocument', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Download payoff documents
export const downloadPayoffDocument = async (accountNumber, documentId) => {
  try {
    const data = {
      'accountNumber': accountNumber,
      'documentId': documentId,
      'privateLabelUrl': Cookies.get("privateLabelUrl"),
      'browser': Cookies.get("browser"),
      'appSource': getAppSource(),
      'userType': getUserType(),
      'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected({
      url: `${baseUrl}/Payoff/DownloadDocument`,
      method: 'POST',
      responseType: 'blob', 
      data: data,
    })
    return response.data;
  } catch (e) {
    return null
  }
}

export const getLandingBanners = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const tiles = await axiosPublic.post('/OT/GetBanner', data);
    if (tiles && tiles.data && tiles.data.responseData.lstResponseStatus[0].statusCode === '0') {
      return tiles.data
    }
  } catch (e) {
    return []
  }
}

export const getRefinanceBanner = async () => {
  const data = {
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  }

  try {
    const response = await axiosProtected.post('/Dashboard/GetRefinanceBanner', data);
    return response.data
  } catch (e) {
    return []
  }
}

export const eaWatchedVideo = async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/Dashboard/SaveEAWatchedVideo', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const saveInsuranceActivity = async (data)  => {
  try {
    if (Cookies.get('privateToken')) {
      const response = await axiosProtected.post('/Covered/SaveInsuranceActivity', data);
      return response;
    } else {
      const response = await axiosPublic.post('/OT/SaveInsuranceActivity', data);
      return response;
    }
  } catch (e) {
    return null
  }
}

export const getConsent = async (data)  => {
  try {
      const response = await axiosProtected.post('/Covered/GetConsent', data);
      return response.data;
  } catch (e) {
    return null
  }
}

export const partnerConsents = async (accountNum) => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/FCC/GetPartnerConsents', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const savePartnerConsents = async (data) => {
  try {
    const response = await axiosProtected.post('/FCC/SavePartnerConsent', data);
    return response.data;
  } catch (e) {
    return null
  }
}

export const downloadFnmaDocument = async (data) => {
  try {
    const response = await axiosProtected({
      url: `${baseUrl}/FNMA/DownloadDocument`,
      method: 'POST',
      responseType: 'blob', 
      data: data,
    })
    return response;
  } catch (e) {
    return null
  }
}



export const propertyRepairDisplay = (accountNum) => async dispatch => {
  const data = {
    "accountNumber": accountNum,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": "",
  };

  try {
    const response = await axiosProtected.post('/FEMA/GetDisasterInfo', data);
    if (response && response?.data?.responseData?.lstResponseStatus && (response?.data?.responseData.lstResponseStatus[0]?.statusCode == 0)) {
      dispatch({
        type: PROPERTY_REPAIR,
        payload: response.data?.responseData.responseData
      })
    }
    return response.data;
  } catch (e) {
    return null
  }
}

export const clearPropertyRepair = () => async dispatch => {
  dispatch({
    type: PROPERTY_REPAIR,
    payload: []
  })
}

//Upload disaster claim documents
export const uploadDisasterClaimDocuments = async (formData) => {
  try {
    const response = await axiosProtected.post('/FEMA/SaveRepairResponse', formData);
    return response.data;
  } catch (e) {
    return null
  }
}

//Delete disaster claim documents
export const deleteDisasterClaimDocument = async (accountNumber, documentId) => {
  try {
    const data = {
    'accountNumber': accountNumber,
    'id': documentId,
    'privateLabelUrl': Cookies.get("privateLabelUrl"),
    'browser': Cookies.get("browser"),
    'appSource': getAppSource(),
    'userType': getUserType(),
    'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected.post('/FEMA/DeleteDocument', data);
    return response.data;
  } catch (e) {
    return null
  }
}

//Download disaster claim documents
export const downloadDisasterClaimDocument = async (accountNumber, documentId) => {
  try {
    const data = {
      'accountNumber': accountNumber,
      'id': documentId,
      'privateLabelUrl': Cookies.get("privateLabelUrl"),
      'browser': Cookies.get("browser"),
      'appSource': getAppSource(),
      'userType': getUserType(),
      'ipAddress': Cookies.get('publicIp')
    }
    const response = await axiosProtected({
      url: `${baseUrl}/FEMA/DownloadDocument`,
      method: 'POST',
      responseType: 'blob', 
      data: data,
    })
    return response.data;
  } catch (e) {
    return null
  }
}

export const saveDisasterPopupResponse = async (demaDisasterClaims) => {
  const data = {
    "femaDisasterPopUpModel": demaDisasterClaims,
    "privateLabelUrl": Cookies.get("privateLabelUrl"),
    "ipAddress": Cookies.get('publicIp'),
    "browser": Cookies.get("browser"),
    "appSource": getAppSource(),
    "userType": getUserType(),
    "userName": ""
  };

  try {
    const response = await axiosProtected.post('/FEMA/SaveDisasterPopUpResponse', data);
    return response.data;
  } catch (e) {
    return null
  }
}

